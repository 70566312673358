import React from 'react';
import styled, { css } from 'styled-components';

import {
	ContentToRichText,
	getComponentSettings,
	isExternalUrl,
} from 'libs/content';
import Heading from 'libs/heading';
import Link, { AnchorLink } from 'components/Link';
import DefaultImage from 'images/placeholders/default-image-post.jpg';
import SanitizeHtml from 'libs/SanitizeHtml';
import { AnimationWrap } from 'libs/animations';
import LazyImage from 'components/LazyImage';

//#region Styling
const ImageWrap = styled.div`
	margin-bottom: 30px;
	height: 100%;
	width: 100%;
	${p =>
		p.$padding &&
		css`
			padding: 0 30px;
			${p =>
				p.theme.media.smallOnly(css`
					padding: 0 20px;
				`)};
		`}

	${p =>
		p.$imgType === 'image/svg+xml' &&
		css`
			width: ${p => (p.$width && `${p.$width}px`) || '250px'};
			margin-bottom: 0;
			${p =>
				p.theme.media.smallOnly(css`
					margin-bottom: 20px;
				`)};
			${p =>
				p.$tinyimgs &&
				css`
					margin-bottom: 10px;
					${p =>
						p.theme.media.smallOnly(css`
							margin-bottom: 10px;
						`)};
				`}
		`}


	${p =>
		p.$imgType !== 'image/svg+xml' &&
		css`
			margin-bottom: 0;
		`}

	${p =>
		!!p.$imagewrapwidth &&
		css`
			width: auto;
		`} 

	${p =>
		p.$height &&
		!p.$tinyimgs &&
		css`
			svg {
				min-height: ${p.$height}px !important;
			}
		`}
`;

const Text = styled.div`
	width: 100%;
	${p =>
		!!p.$imagewrapwidth &&
		css`
			flex: 1;
			min-width: ${p.$imagewrapwidth === '1/3'
				? 'calc(100%/3*2)'
				: p.$imagewrapwidth === '1/2'
				? '50%'
				: '100%'};
		`}
	p {
		margin: 0 0 10px;
		&:last-child {
			margin: 0px;
		}
		span {
			display: block;
		}
	}
	button {
		margin-top: 20px;
	}
`;

const BoxWrap = styled(AnimationWrap)`
	width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	margin: 0 0 20px;
	padding: 0;
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100% !important;
			padding-right: 0 !important;
			padding-left: 0 !important;
			margin: 0 0 40px;
		`)}
	&:last-of-type {
		${p =>
			p.theme.media.mediumDown(css`
				margin-bottom: 0;
			`)}
	}
`;

const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: 0 -20px;
	align-items: flex-start;
	justify-content: center;
	${p =>
		p.theme.media.mediumDown(css`
			margin: 0;
		`)}
	${p =>
		p.theme.media.mediumOnly(css`
			width: auto;
		`)}
	
	/** Tiny images/icons */
	${p =>
		p.$tinyimgs &&
		css`
			justify-content: space-evenly !important;
			${p =>
				p.theme.media.mediumOnly(css`
					margin: 0 auto;
				`)}
			${BoxWrap} {
				${Text} {
					margin-top: 0;
					text-align: center;
					h3 {
						font-size: 17px;
						line-height: 27px;
						margin: 0;
					}
				}
				svg {
					width: 35px !important;
					height: 35px !important;
					min-height: auto;
					${p =>
						p.theme.media.medium(css`
							width: 45px !important;
							height: 45px !important;
						`)};
				}
			}
		`} 

	/** More than one per row and has images */	
	${p =>
		p.$perrow > 1 &&
		p.$hasimages &&
		css`
			${ImageWrap} {
				margin-bottom: 20px;
			}
		`}

	/** 1 per row */	
	${p =>
		p.$perrow === 1 &&
		css`
			justify-content: ${p =>
				(p.$centered === 'false' && 'flex-start') || 'center'};
			${BoxWrap} {
				${p =>
					p.theme.media.medium(css`
						flex-direction: ${p =>
							(p.$imageposition === 'right' && 'row-reverse') ||
							'row'} !important;
						margin: 0px 20px 30px;
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						margin: 0 0 20px !important;
						> div {
							width: 100%;
							text-align: center;
							margin-top: 20px;
							svg {
								width: auto;
							}
						}
					`)}

				${p =>
					p.theme.media.mediumDown(css`
						margin: 0 0 20px !important;
					`)}

				
				${p =>
					p.$hasimages &&
					css`
						margin: 0 20px 30px !important;
						${ImageWrap} {
							padding: 0;
							svg {
								min-height: auto;
								display: block;
								${p =>
									p.theme.media.smallOnly(css`
										margin: 0 auto;
									`)}
							}
						}
						${Text} {
							min-width: auto !important;
							${p =>
								p.theme.media.medium(css`
									margin-top: 0 !important;
									padding: ${p =>
										(p.$imageposition === 'right' &&
											'0 20px 0 0') ||
										'0 0 0 20px'} !important;
								`)}
							h3 {
								margin-bottom: 10px;
							}
						}
					`}
			}
			${p =>
				p.$tinyimgs &&
				css`
					${Text} {
						text-align: ${p =>
							(p.$centered === 'false' && 'left !important') ||
							'center'};
					}
				`}
		`}
	
	/** 2 per row */	
	${p =>
		p.$perrow === 2 &&
		css`
			${p =>
				p.theme.media.mediumOnly(css`
					margin: 0 -20px;
				`)}
			${BoxWrap} {
				width: calc(50% - 40px);
				${p =>
					p.theme.media.large(css`
						width: calc(50% - 40px);
					`)}
				${p =>
					p.theme.media.mediumDown(css`
						width: calc(50% - 40px);
						margin: 0 20px 30px !important;
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						width: 100%;
						margin: 0 0 30px !important;
					`)}
			}
		`}
	
	/** 3 per row */	
	${p =>
		p.$perrow === 3 &&
		css`
			align-items: flex-start;
			${p =>
				p.theme.media.mediumOnly(css`
					margin: 0 -20px;
				`)}
			${BoxWrap} {
				width: calc(50% - 40px);
				${p =>
					p.theme.media.large(css`
						width: calc(33.3334% - 40px);
					`)}
				${p =>
					p.theme.media.mediumDown(css`
						width: calc(50% - 40px);
						margin: 0 20px 30px !important;
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						width: 100%;
						margin: 0 0 30px !important;
					`)}
			}
		`}

	/** 4 per row */
	${p =>
		p.$perrow === 4 &&
		css`
			${p =>
				p.theme.media.mediumOnly(css`
					margin: 0 -20px;
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					margin: 0;
				`)}
			${BoxWrap} {
				${p =>
					p.theme.media.large(css`
						width: calc(25% - 40px);
					`)}
				${p =>
					p.theme.media.mediumDown(css`
						width: calc(50% - 40px);
						margin: 0 20px 30px !important;
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						width: 100%;
						margin: 0 0 30px !important;
					`)}
			}
		`}

	${BoxWrap} {
		text-align: ${p => (p.$centered === 'true' ? 'center' : 'inherit')};
		flex-direction: column;
		// Zero margins for last items on last row
		&:nth-child(${p => p.$perrow}n+1):nth-last-child(
				-n + ${p => p.$perrow}
			),
		&:nth-child(${p => p.$perrow}n+1):nth-last-child(-n + ${p => p.$perrow})
			~ div {
			${p =>
				p.theme.media.large(css`
					margin-bottom: 0 !important;
				`)}
		}
		svg {
			min-height: ${p => (!p.$tinyimgs && '220px') || 'auto'};
			//max-width: 100%;
			display: inline-block;
			${p =>
				p.theme.media.smallOnly(css`
					min-height: auto;
				`)};
		}

		img {
			border-radius: ${p => p.theme.utils.borderRadius};
		}

		${p =>
			p.theme.media.mediumOnly(css`
				margin: ${p => (p.$multiplerows ? '0 20px 40px' : '0 20px 0')};
			`)}
		${p =>
			p.theme.media.large(css`
				margin: ${p => (p.$multiplerows ? '0 20px 60px' : '0 20px 0')};
			`)}

		${p =>
			p.$centered === 'true' &&
			css`
				text-align: center;
			`}

		${p =>
			p.$centered !== 'true' &&
			css`
				display: flex;
				align-items: center;
				flex-direction: column;

				${Text} {
					width: 100%
						${p =>
							p.theme.media.medium(css`
								flex: 1;
							`)};
				}
			`}
	}
`;

const ReadMore = styled.div`
	margin-top: auto;
	text-decoration: underline;
	color: ${p => p.theme.colors.blue600};
`;

//#endregion

/**
 * Represents elements a grid
 * @param {array} elements - The elements in the grid
 * @param {array} files - The files in the grid
 * @param {integer} perrow - The maximum number of elements per row
 * @param {string} headinglevel - The heading level for the title in the box
 * @param {...object} settings - The rest of the properties for the component
 * @param {string} hasimages - Whether or not the grid elements has images
 * @param {...object} props - Rest of the parameters for the component
 */
export default function GridBoxes({
	elements = [],
	files = [],
	perrow = 3,
	settings = [],
	headinglevel = 'h3',
	hasimages = 'false',
	...props
}) {
	if (!elements?.length > 0 && !files?.length > 0) return;

	return (
		<Grid
			$perrow={perrow > elements?.length ? elements?.length : perrow}
			$imageposition={
				(elements?.some(el => el?.imageposition?.includes('høyre')) &&
					'right') ||
				'left'
			}
			$tinyimgs={settings?.tinyimgs === 'true'}
			$multiplerows={parseInt(elements?.length) + 1 > perrow}
			$centered={settings?.centered}
			$hasimages={
				elements?.some(
					el =>
						el?.image?.file?.url !== undefined ||
						el?.image?.svg?.originalContent !== undefined
				)
					? 'true'
					: 'false'
			}
			{...settings}>
			{elements?.length > 0 &&
				elements.map((element, i) => (
					<Box
						index={i}
						key={i}
						{...element}
						settings={settings}
						headinglevel={headinglevel}
						hasimages={hasimages}
						transitions={props?.transitions}
						perrow={perrow}
					/>
				))}

			{files?.length > 0 &&
				files.map((file, i) => (
					<Box
						index={i}
						key={i}
						{...file}
						settings={settings}
						headinglevel={headinglevel}
						transitions={props?.transitions}
						perrow={perrow}
					/>
				))}
		</Grid>
	);
}

/**
 * Represents a box in the grid
 * @param {string} headinglevel - The heading level for the title in the box
 * @param {object} settings - The settings for the component
 * @param {...object} props - The rest of the properties for the box
 */
function Box({ headinglevel, settings, ...props }) {
	const boxSettings = getComponentSettings({ settings: props?.settings });

	const imageWrapWidth = props?.imageposition?.includes('1/3')
		? '1/3'
		: props?.imageposition?.includes('1/2')
		? '1/2'
		: '1/1';

	const showLink = ![
		'ContentfulKomponentBildeOgInnhold',
		'ContentfulKomponentInnhold',
	].includes(props?.internal?.type);

	if (settings?.tinyimgs === 'true' && props?.hasimages === 'true') {
		return (
			<BoxWrap
				$tinyimgs={settings?.tinyimgs === 'true'}
				hasImage={true}
				doanimate={props?.transitions}
				delay={(props?.index && 0.5 * props.index) || 0}
				direction="down">
				<ImageOutput
					img={props?.image}
					settings={settings}
					imagewrapwidth={imageWrapWidth}
				/>

				<Text
					$tinyimgs={settings?.tinyimgs === 'true'}
					$imagewrapwidth={imageWrapWidth}
					$imageratio={settings?.imagecrop || 'none'}>
					<BoxTextContent
						boxSettings={boxSettings}
						showLink={showLink}
						{...props}
					/>
				</Text>
			</BoxWrap>
		);
	}

	return (
		<BoxWrap
			$tinyimgs={settings?.tinyimgs === 'true'}
			$hasimages={props?.hasimages === 'true'}>
			{props?.hasimages === 'true' && (
				<AnimationWrap
					doanimate={props?.transitions}
					delay={(props?.index && 0.6 * props.index) || 0}
					direction={(props?.perrow === 1 && 'right') || 'down'}>
					<ImageOutput
						img={props?.image}
						settings={settings}
						imagewrapwidth={imageWrapWidth}
					/>
				</AnimationWrap>
			)}
			<Text
				$tinyimgs={settings?.tinyimgs === 'true'}
				$imagewrapwidth={imageWrapWidth}
				$imageratio={settings?.imagecrop || 'none'}>
				<AnimationWrap
					doanimate={props?.transitions}
					delay={(props?.index && 0.2 * props.index) || 0}
					direction={(props?.perrow === 1 && 'left') || 'down'}>
					<BoxTextContent
						boxSettings={boxSettings}
						showLink={showLink}
						{...props}
					/>
				</AnimationWrap>
			</Text>
		</BoxWrap>
	);
}

function BoxTextContent({ boxSettings, showLink, ...props }) {
	return (
		<>
			{props?.title && !boxSettings?.hidetitle && (
				<Heading level="h3" style={{ marginBottom: '10px' }}>
					{props?.title}
				</Heading>
			)}
			{props?.text?.raw ? (
				<ContentToRichText data={props?.text} />
			) : props?.text ? (
				<SanitizeHtml html={props?.text} />
			) : null}

			{showLink && props?.link && props?.linktext && (
				<ReadMore>
					{!isExternalUrl(props?.link) ? (
						<Link
							to={props?.link}
							title={props?.linktext}
							target={props?.target}>
							{props?.linktext}
						</Link>
					) : (
						<AnchorLink
							href={props?.link}
							title={props?.linktext || props?.title}
							target="_blank"
							rel="noreferrer">
							{props?.linktext}
						</AnchorLink>
					)}
				</ReadMore>
			)}
		</>
	);
}

function ImageOutput({ img, settings, imagewrapwidth = '1/2' }) {
	if (img?.mimetype === 'image/svg+xml' && img?.svg?.originalContent) {
		return (
			<ImageWrap
				$height={img?.height}
				$width={img?.width}
				$tinyimgs={settings?.tinyimgs === 'true'}
				$padding={!settings?.tinyimgs}
				$imagewrapwidth={imagewrapwidth}
				$imgType={img?.mimetype}>
				<SanitizeHtml html={img?.svg?.originalContent} />
			</ImageWrap>
		);
	}

	if (img?.file?.url) {
		return (
			<ImageWrap
				$padding={settings?.tinyimgs && settings?.centered}
				$tinyimgs={settings?.tinyimgs === 'true'}
				$imgType={img?.mimetype}
				$imagewrapwidth={imagewrapwidth}
				$ratio={settings?.imagecrop || 'none'}>
				<LazyImage
					file={{ url: img?.file?.url }}
					alt={img?.alt || img?.title}
					width={img?.width}
					height={img?.height}
					ratio={settings?.imagecrop || 'none'}
				/>
			</ImageWrap>
		);
	}

	return (
		<ImageWrap
			$padding={!settings?.tinyimgs && settings?.centered}
			$imgType={img?.mimetype}>
			<img src={DefaultImage} alt={img?.alt || img?.title} />
		</ImageWrap>
	);
}
