import React from 'react';
import styled, { css } from 'styled-components';

import Link, { AnchorLink } from 'components/Link';
import Heading from 'libs/heading';
import DefaultImage from 'images/placeholders/default-image.jpg';
import ArrowIcon from 'images/icons/ArrowLeftLong';
import LazyImage from 'components/LazyImage';
import { shortenString } from 'libs/content';

//#region Styling
const Title = styled(Heading)`
	color: ${p => p.theme.colors.white};
	margin: 0;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 20px;
			line-height: 26px;
		`)}
`;

const Text = styled.p`
	margin: 10px 0 0;
	color: ${p => p.theme.colors.white};
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			line-height: 22px;
		`)}
`;

const BoxWrap = styled.div`
	flex: 1 1 0;
	overflow: hidden;
	height: 100%;
	position: relative;
	border-radius: ${p => p.theme.utils.borderRadius};
	background: ${p => p.theme.colors.grey200};
	${p =>
		p.theme.media.smallOnly(css`
			height: auto;
		`)};
`;

const Content = styled.div`
	width: 100%;
	padding: 175px 22px 22px;
	transition: all 0.35s ease;
	position: absolute;
	bottom: 0;
	z-index: 2;
	border-radius: 0 0 3px 3px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 100px 20px 20px;
			text-align: center;
		`)}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
		transition: all 0.35s ease;
		background-image: linear-gradient(
			to bottom,
			rgba(40, 41, 44, 0),
			rgba(0, 0, 0, 0.8)
		);
		${p =>
			p.theme.media.smallOnly(css`
				background-image: linear-gradient(
					to bottom,
					rgba(40, 41, 44, 0),
					rgba(0, 0, 0, 0.95)
				);
			`)}
	}
`;

const InnerContent = styled.div`
	position: relative;
	z-index: 2;
`;

const Arrow = styled(ArrowIcon)`
	transform: rotate(180deg);
	width: 20px;
	height: 20px;
	margin-left: 10px;
	transition: margin-left 0.2s ease;
	vertical-align: middle;
	${p =>
		p.theme.media.smallOnly(css`
			width: 14px;
			height: 14px;
		`)}
`;

const LinkSharedStyle = () => css`
	transition: all 0.35s ease;
	&:hover {
		cursor: pointer;
		${Content} {
			padding-bottom: 30px;
			&:after {
				opacity: 0.7;
			}
		}
		${Arrow} {
			margin-left: 15px;
		}
	}
`;

const InternalLink = styled(Link)`
	${LinkSharedStyle}
`;

const ExternalLink = styled(AnchorLink)`
	${LinkSharedStyle}
`;

const Image = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	.lazy-image {
		height: 100%;
		width: 100%;
		&__main {
			${p =>
				p.theme.media.medium(css`
					position: absolute;
					top: 0;
					left: 0;
					width: 100% !important;
					height: 100% !important;
					object-fit: cover;
				`)}
		}
	}
`;

const LeftColumn = styled.div`
	flex: 1 1 100%;
	${p =>
		p.theme.media.medium(css`
			flex: 1 1 60%;
			margin-right: 10px;
			${BoxWrap} {
				min-height: 100%;
			}
		`)}
	${BoxWrap} {
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: 20px;
			`)}
	}
`;

const RightColumn = styled.div`
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;
	${p =>
		p.theme.media.medium(css`
			flex: 1 1 40%;
			margin-left: 10px;
			${BoxWrap} {
				min-height: 344px;
			}
		`)}
	${BoxWrap} {
		flex: 1;
		&:first-of-type {
			margin-bottom: 10px;
		}
		&:last-of-type {
			margin-top: 10px;
		}
	}
`;

const Columns = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			flex-direction: column;
		`)}
`;

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	${p =>
		p.theme.media.smallOnly(css`
			flex-direction: column;
		`)}
`;
//#endregion

/**
 * Represents image-elements a grid
 * @param {array} elements - The elements for the component
 * @param {string} headinglevel - The heading level for the title in the box
 */
export default function ImageBoxes({ elements, headinglevel = 'h3' }) {
	if (!elements?.length > 0) return;

	return (
		<Wrapper>
			<Columns>
				{/** First linkbox */}
				<LeftColumn>
					<Box
						headinglevel={headinglevel}
						first="true"
						{...elements[0]}
					/>
				</LeftColumn>

				{/** Second and third linkbox */}
				{elements.length > 1 && (
					<RightColumn>
						{elements.map((box, i) => {
							if (i === 0 || i > 2) return null;

							return <Box key={i} headinglevel="h3" {...box} />;
						})}
					</RightColumn>
				)}
			</Columns>
		</Wrapper>
	);
}

/**
 * Represents a box
 * @param {string} headinglevel - The heading level for the title in the box
 * @param {...object} props - The rest of the properties for the box
 */
function Box({ headinglevel, ...props }) {
	return (
		<BoxWrap>
			<LinkComponent
				title={props?.linktext || props?.title}
				target={(!props?.internal && '_blank') || props?.target}
				{...props}>
				<Image>
					{props?.image?.file?.url ? (
						<LazyImage
							{...props?.image}
							fit="fill"
							width={768}
							height={(props?.first === 'true' && 768) || 480}
							alt={props?.image?.alt || props?.title}
						/>
					) : (
						<img src={DefaultImage} alt={props?.title} />
					)}
				</Image>
				<Content>
					<InnerContent>
						{props?.title && (
							<Title level={headinglevel}>
								<span>{props?.title}</span>
								{!props?.text && <Arrow />}
							</Title>
						)}
						{props?.text && (
							<Text>
								<span>{shortenString(props?.text, 75)}</span>
								<Arrow />
							</Text>
						)}
					</InnerContent>
				</Content>
			</LinkComponent>
		</BoxWrap>
	);
}

/**
 * Represents the link wrapper inside the box
 * @param {boolean} internal - Whether the link is an internal link or not
 * @param {React.ReactNode} children - The child components
 * @param {...object} props - The rest of the properties for the box
 */
function LinkComponent({ internal, children, ...props }) {
	if (internal) {
		return (
			<InternalLink to={props?.link} {...props}>
				{children}
			</InternalLink>
		);
	}

	return (
		<ExternalLink href={props?.link} {...props}>
			{children}
		</ExternalLink>
	);
}
